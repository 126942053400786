import React from "react";
import { useNavigate } from "react-router-dom";

function MyPartOfTheInternet() {
  const navigate = useNavigate();

  return (
    <div
      className="max-w-[800px] px-12 mx-auto my-0 my-12 tablet:my-36"
      id="container"
    >
      <button className="link mb-4" onClick={() => navigate(-1)}>
        Back
      </button>
      <div className="flex flex-row justify-between gap-4 items-center mb-16">
        <div className="text-left w-full font-eb-garamond gap-2 flex flex-col">
          <h1 className="text-black-primary font-eb-garamond text-4xl">
            my part of the internet
          </h1>
        </div>
        <p>a running list of all the thiings i love on the internet</p>
      </div>
      <div className="text-left font-eb-garamond text-dark-gray">
        <ul className="list-disc pl-5">
          <li>
            <a
              href="https://www.organism.earth/library/document/spectrum-of-love"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              the spectrum of love by alan watts.
            </a>{" "}
            <br />
            found this 3 years ago. till this day, it still forms the very basis
            of my own personal philosophy.
          </li>
          <li>
            <a
              href="https://willhath.substack.com/p/500-sleepless-hours"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              500 sleepless hours
            </a>{" "}
            <br />
            -- just so raw
          </li>
          <li>
            <a
              href="https://www.swyx.io/simplicity-rush"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              simplicity is more beautiful when complexity is understood
            </a>{" "}
          </li>
          <li>
            <a
              href="https://arxiv.org/abs/2405.07987"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              nature is converging
            </a>{" "}
            <br />
            -- representations are discoveries of our universe, that do not
            belong to us, but the world around us. how beautiful.
          </li>
          <li>
            <a
              href="https://stanforddaily.com/2020/11/17/lines-of-love-transforming-our-definition-of-platonic-love/"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              love, at ease
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MyPartOfTheInternet;
