import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import sanityClient from "../client.js";
import { StarIcon } from "@sanity/icons";
import { motion, AnimatePresence } from "framer-motion";

function TheLab() {
  const navigate = useNavigate();
  const [allPostsData, setAllPosts] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("star");
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        publishedAt,
        description,
        categories,
        caption,
        mainImage{
          asset->{
          _id,
          url
        }
      },
      categories[]->{
        title
      }
    }`
      )
      .then((data) => {
        // Sort the posts by date, newest first
        const sortedData = data.sort(
          (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
        );
        setAllPosts(sortedData);
      })
      .catch(console.error);
  }, []);

  const categories = [
    "star",
    "everything",
    "projects",
    "thoughts",
    "interpretability",
    "education",
  ];

  const filteredPosts = allPostsData
    ? allPostsData.filter((post) => {
        const categoryMatch =
          selectedCategory === "everything" ||
          (post.categories &&
            post.categories.some(
              (cat) => cat.title.toLowerCase() === selectedCategory
            ));
        return categoryMatch;
      })
    : [];

  const handleCategoryChange = (category) => {
    setIsAnimating(true);
    setTimeout(() => {
      setSelectedCategory(category);
      setIsAnimating(false);
    }, 200); // Reduced from 300ms to 200ms for snappier animation
  };

  const getCategoryLink = (category) => {
    switch (category) {
      case "star":
        return "/the-lab";
      case "everything":
        return "/everything-else";
      case "thoughts":
        return "/my-thoughts";
      case "sae-features":
        return "/sae-features";
      default:
        return "/the-lab";
    }
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case "star":
        return <StarIcon />;
      case "sae-features":
        return "🧠";
      default:
        return null;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="max-w-[1200px] px-12 mx-auto my-0 my-12 tablet:my-36"
      id="container"
    >
      <button className="link" onClick={() => navigate("/")}>
        Back
      </button>
      <div className="flex text-left flex-col justify-between gap-2 my-12">
        <div className="w-full font-eb-garamond flex flex-col">
          <h1 className="text-black-primary font-eb-garamond text-4xl">
            the lab
          </h1>
        </div>
        <p>
          trying to work with my{" "}
          <a
            href="https://notes.andymatuschak.org/About_these_notes?stackedNotes=z21cgR9K3UcQ5a7yPsj2RUim3oM2TzdBByZu"
            className="link"
          >
            garage door up
          </a>
          .
        </p>
      </div>
      <div className="flex flex-col gap-4 items-start mb-8">
        <div className="flex gap-2 flex-wrap">
          {categories.map((category) => (
            <button
              key={category}
              className={`flex items-center px-4 py-2 border border-black ${
                selectedCategory === category
                  ? "bg-black text-white"
                  : "text-black"
              }`}
              onClick={() => handleCategoryChange(category)}
            >
              {category === "star" ? "things to look at first" : category}
              {category === "star" && <StarIcon className="ml-2" />}
            </button>
          ))}
        </div>
      </div>
      <AnimatePresence>
        {!isAnimating && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }} // Reduced from 0.3s to 0.2s
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
          >
            {filteredPosts.map((post, index) => {
              const rotation = Math.random() * 2 - 1; // Random rotation between -1 and 1 degrees
              const isStarPost =
                post.categories &&
                post.categories.some(
                  (cat) => cat.title.toLowerCase() === "star"
                );
              return (
                <motion.div
                  key={post.slug.current}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2, delay: index * 0.03 }} // Reduced duration and delay
                  className="bg-transparent text-black p-4 border border-black hover:bg-black hover:text-white cursor-pointer"
                  style={{ transform: `rotate(${rotation}deg)` }}
                  onClick={() => navigate(`/the-lab/${post.slug.current}`)}
                >
                  <div className="font-bold text-lg flex items-center">
                    {post.title}
                    {isStarPost && <StarIcon className="ml-2" />}
                  </div>
                  <div className="text-sm text-gray-400">
                    {new Date(post.publishedAt).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </div>
                  <div className="text-sm">{post.caption}</div>
                </motion.div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default TheLab;
